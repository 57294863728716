import React from "react"
import Layout from "@components/layout";
import FormsParts from "@components/forms-contactform7";

// Import SVG
import pticonSVG1 from '@images/ib_new_img1.webp';
import pticonSVG2 from '@images/partner_icon2.svg';
import pticonSVG3 from '@images/partner_icon3.svg';
import pticonSVG4 from '@images/partner_icon4.svg';

// 言語用設定用ファイル
import { LangSetting } from "@components/language-setting.js";
import langEn from "@content/locales/en/ib.json";
import langJa from "@content/locales/ja/ib.json";

import { SeoContents } from "@components/seo-data.js" // Seo情報設定

const Ib = ( props ) => {
  
  // 言語設定
  const setLang = LangSetting( props, langEn, langJa ).SetLang;
  const postLang = LangSetting( props, langEn, langJa ).PostLang;

  // Seo情報設定
  const pathName = 'ib'; // ファイル名
  const title = postLang["page-title"];
  const description = postLang["page-description"];
  const keywords = postLang["page-keywords"];
  const seoTag = SeoContents( pathName, setLang, title, description, keywords, "", "" );
  
   // 言語別URL先頭設定
  let langUrl;
  (setLang === 'en') ? langUrl = '/' : langUrl = '/' + setLang + '/';
  const partnerPath1 = langUrl + "pamm/";
  const partnerPath2 = langUrl + "white-label/";

  return (
  <>
    {seoTag}
    <Layout>
      <div className="FV pt-5 pb-8 bg-fixed" langType={setLang}>
        <div className="max-w-xl mx-auto px-6 md:max-w-5xl md:pt-5 pt-2 ">
          <div className=" text-left h1_filed">
            <h1 className="md:text-[60px] text-[35px] font-semibold">{postLang["ibp-h1"]}</h1>
            <div className="text-base max-w-[680px]">
              <p>{postLang["ibp-h1-txt"]}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-xl mx-auto  pt-0 md:pt-5 pb-5 md:max-w-5xl px-6  md:mb-5">
        <div className="mb-10 flex flex-wrap justify-between mt-10">
          <div class="text-left  md:mb-5 mb-10 md:max-w-[600px]">
            <h2 className="font-semibold tracking-wide text-[26px] md:text-[40px] mb-5">{postLang["ibp-h2"]}</h2>
            <p className="leading-7">{postLang["ibp-txt_1"]}{postLang["ibp-txt_2"]}{postLang["ibp-txt_3"]}{postLang["ibp-txt_4"]}</p>
          </div>
          <div className="w-[100%] md:max-w-[350px]   ">
             <img src={pticonSVG1} alt="" />
          </div>
        </div>

      </div>
      <div className="Feature pt-5 bg-fixed">
        <div className="max-w-xl mx-auto  md:max-w-5xl px-6 mb-10">
          <div className="text-left mt-10 md:mb-10 mb-5">
            <h2 className="font-semibold tracking-wide  text-[26px] md:text-[40px]">{postLang["ibp-h2_2"]}</h2>
          </div>
          <p className="text-left md:mb-20 mb-10 ">{postLang["ibp-h2-txt"]}<br></br>{postLang["ibp-h2-txt_2"]}</p>
          <div className="max-w-2xl  mb-10"><FormsParts langType={setLang} /></div>
        </div>
        <div className="mt-11 py-14 py-14  md:py-10 md:py-10 ">
          <div className="flex-1 mx-auto max-w-3xl md:flex md:justify-around">
            <a href={partnerPath1} className="rounded block  md:max-w-xs mx-5 text-center text-white font-bold py-5 px-16 my-2 bg-black hover:scale-[1.05] duration-300 ">
            <p className="inline-block"><span>{postLang["pamm-h1"]}</span><br></br><span className="text-[10px] leading-4 inline-block">{postLang["pamm-h1-txt"]}</span></p>
            </a>
            <a href={partnerPath2} className="rounded block  md:max-w-xs mx-5 text-center text-white font-bold py-5 px-16 my-2 bg-black hover:scale-[1.05] duration-300 ">
            <p className="inline-block"><span>{postLang["wrp-h1"]}</span><br></br><span className="text-[10px]">{postLang["wrp-h1-txt"]}</span></p>
            </a>
          </div>
        </div>
      </div>
    </Layout>
    
  </>
  )
}
export default Ib